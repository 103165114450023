.view-container {
	margin: 0 20px;
	margin-left: 200px;
}

.tables-container {
	background: #fafbfc;
}

.tables-container .react-datepicker-popper {
	z-index: 2;
}

.last-save-time {
	position: fixed;
	top: 170px;
	width: 200px;
	left: 1em;
	height: 50px;
	padding: 0 10px;
	color: #ccc;
	font-size: 0.8em;
/*    background-color: #fff3cd;
    border-color: #ffeeba;*/
}

.last-save-time div,
.last-save-time time,
.last-save-time .saving-spinner {
	color: #ccc;
}

.saving-spinner {
	font-size: .875em;
}

.project-comment {
	position: fixed;
	top: 69px;
	width: 200px;
	left: 1em;
	padding: 0 10px;
	color: #ccc;
	font-size: 0.73em;
	height: 100px;
    overflow: hidden;
    background-color: #fafbfc;
}

.project-comment span {
	display: block;
    font-size: 0.95em;
    font-weight: 600;
}