.project-okuds .nav {
	display: inline-flex;
    float: right;
}

.project-okuds .nav-item {
	margin: 0 10px;
}

.project-okuds .nav-link {
	color: #007bff !important;
}

.project-okud dl {
	margin-bottom: .3em;
}

.project-okud dt,
.project-okud dd  {
	display: inline-block;
	float: none;
	padding: 0;
	font-weight: normal;
	font-size: 90%;
}

.project-okud dt {
	margin-right: 5px;
	color: #ccc;
}

.project-okud dd {
	margin-right: 10px;
}

.project-okud-date-change,
.project-okud-add {
	color: #ccc;
}

.project-okud-date-change {
	display: none;
}

.project-okud-title {
	margin-bottom: 1em;
}

.project-okud-code {
	color: #ccc;
	font-size: 90%;
}

.project-okud-label {
	padding-left: .5em;
}

.project-okud-add {
	margin: 0 10px;
}

.project-okud-item {
	margin-bottom: 1em;
}

.project-okud-rm-link {
	visibility: visible;
	color: #ccc;
}

.project-okud-rm-link:hover {
	color: #f00;
}

/*.project-okud-actions {
	visibility: hidden;
}

.project-okud-item:hover .project-okud-actions {
	visibility: visible;
}*/