.userSelect__control {
	min-width: 200px;
}

.userSelect__value-container {
	height: 35px;
}

.userSelect__control .userSelect__clear-indicator path {
	color: #ff000073;
}