*{
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	color: #404040;
}
body{
	background: #FAFBFC;
	color: #404040;
	margin: 0;
	/*margin-bottom: 150px;*/
}
a, a:hover{
	text-decoration: none;
}
li{
	list-style-type: none;
}
dd{
	margin: 0;
}
dl dt {
 float:left;
 width:auto;
}

dl dd {
 padding:5px 0;
}
.flex{
	justify-content: center;
    -ms-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
.flex_align-center{
    -ms-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
.navbar-component {
	background: #FFFFFF;
	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
}
.new-project, .project-list, .user{
	font-weight: 700;
	font-size: 16px;
	cursor: pointer;
	color: #404040!important;
}
.new-project, .project-list {
	color: #2FD18E!important;
	margin-right: 22px;
}
.dropdown-toggle::after{
	color: #2FD18E;
}

.project-section {
	margin-bottom: 1em;
}

.dropdown-item{
	font-weight: 700;
	font-size: 16px;
}
.dropdown-menu {
	border: none;
	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
}
.dropdown-menu:hover .dropdown-item,
.dropdown-menu:hover .fa-sign-out-alt{
	color: #2FD18E;
	transition-duration: .3s;
}
.navbar-expand-lg .navbar-nav .dropdown-menu{
	left: -71px;
}

.bg-light {
	background-color: #fff !important;
}

.report-all_wrap{
	padding: 40px 10px;
	margin-bottom: 26px;
}
.bg_white{
	background-color: #fff;
	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
	border-radius: 5px;
}
.step-header{
	font-size: 28px;
	font-weight: 700;
	text-align: center;
	padding: 31px 0;
	margin: 0;
}
.step-header-actions {
	display: inline-block;
    float: right;
    font-size: initial;
}
.step-header-actions a {
	margin: 0 10px;
}
.report-all_alert{
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	font-weight: 700;
	padding-bottom: 19px;
	margin: 0;
}
.text_allert{
	color: #FF3100;
	font-weight: 700;
}
.button{
	width: 207px;
	height: 55px;
	background: #2FD18E;
	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
	border-radius: 5px;
	font-weight: 700;
	font-size: 15px;
	color: #fff;
	margin: 0 auto;
	
}
.button:hover{
	box-shadow: 0px 0px 0px rgba(187, 202, 209, 0.1);
	background: #6BDCAE;
	transition-duration: .5s;
}
.report_wrap{
	padding: 31px 41px 25px;
	margin-bottom: 26px;
}
.arrow .fas{
	color: #fff;
	margin: 0px 5px;
}
.arrow_green .fas{
	color: #2FD18E;
	margin: 0px 5px;
}
.arrow_out .fas{
	margin: 0px 10px;
}

.loading_wrap{
	border-radius: 5px;
	border: 2px dashed #2FD18E;
	margin: 50px 0 41px;
	cursor: pointer;
	padding: 20px 20px 56px;
}
.loading-ico .fas{
	font-size: 55px;
	color: #2FD18E;
	text-align: center;
	margin-top: 42px;
	width: 100%;
}
.loading-title, .loading-subtitle{
	text-align: center;
	font-size: 18px;
	font-weight: 400;
}
.loading-title{
	margin: 21px 0 10px;
}
.loading-subtitle{
	color: #2FD18E;
}
.table-report {
    border-collapse: collapse;
    border-spacing: 0;
    overflow-x: auto;
}
.tg-item_border{
	border-bottom: 1px solid rgba(230, 242, 245, 0.75);
}
.table-tr .table-td:last-child{
	border-right: 0px solid rgba(230, 242, 245, 0.75);
}

.nav-link {
	cursor: pointer;
}

.pseudo-link {
	border-bottom: 1px dashed;
	cursor: pointer;
}

.breadcrumps-container {
	padding-top: 8px;
	padding-bottom: 8px;
	align-items: center;
	border-bottom: 1px solid #dbdbdb;
}

.breadcrumps-container .breadcrumbs__section:not(:last-child) {
	margin-right: 1em

}

.breadcrumps-container .breadcrumbs__section:not(:last-child):after {
	font-family: "Font Awesome 5 Free";
	content: '\F30B';
	padding-left: 1em;
	display: inline-block;
	vertical-align: middle;
	font-weight: 600;
	color: #ccc;
}

.breadcrumps-container .breadcrumbs__separator {
	display: none;
}

.breadcrumps-container a {
	font-size: 12px;
	color: #777;
}

.sidenav---sidenav---_2tBP {
	background: #2FD18E !important;
}

@media (min-width: 1900px) {
	.view-container {
		margin-left: 0px !important;
	}
	.breadcrumps-container {
		margin-left: 0;
	}
}
@media (max-width: 1699px) {
	.view-container {
		margin-left: 200px !important;
	}
	.breadcrumps-container {
		margin-left: 0;
	}
}


@media (min-width: 1500px) {
	.container {
	    max-width: 1240px;
	}
	.view-table-wrap {
		margin-left: -15%;
	}
	.breadcrumps-container {
		margin-left: 15%;
	}
}
@media (max-width: 1499px) {
	.container {
	    max-width: 1240px;
	}
	.view-table-wrap {
		margin-left: 0%;
	}
	.breadcrumps-container {
		margin-left: 15%;
	}
}

@media (max-width: 1299px) {
	.container {
	    max-width: 1140px;
	}
	.view-table-wrap {
		margin-left: 0%;
	}
	.breadcrumps-container {
		margin-left: 20%;
	}
}

@media (max-width: 991px){
	.breadcrumps-container {
		margin-left: 25%;
	}
}
@media (max-width: 810px){

}

@media (max-width: 768px){
	.tab{
		margin-bottom: 8px;
	}
	.report_li{
	    padding: 10px 0;
	}
	.report_wrap {
	    padding: 31px 25px 25px;
	}
	.card-title {
	    width: 90%;
	}
	.control_step .button{
		margin-bottom: 8px;
		width: 100%;
	}
	.breadcrumps-container {
		margin-left: 30%;
	}
}

@media (max-width: 500px){
	
}

@media (max-width: 450px){
	.report_ul {
	    padding: 0 20px;
	}

}