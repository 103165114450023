.project-decimals {
	width: 100px;
}

.ep-item-period {
	color: #ccc;
	font-size: 90%;
}

.period-label {
	padding-left: 0 !important;
}

.project-is-temp-comment {
	color: #ccc;
	font-size: 90%;
	padding-left: 5px;
}

.project-is-temp-label {
	margin-left: 20px;	
}
