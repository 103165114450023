.project-actions {
	margin: 1em;
}

.project-actions a {
	padding: 1rem 3rem;
}

.project-assignee {
	margin-left: 3em;
}
