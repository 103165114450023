.editor-panel .alert {
	padding: .05rem 1.25rem;
	font-size: 90%;
}

.editor-panel .btn-link {
	font-size: 90%;
}

.btn-draft-remove,
.btn-draft-exit {
	float: right;
    color: #F00;
}

.btn-draft-exit {
	color: #333;
}

.draft-save-time {
	float: right;
	color: #ccc;
}

.draft-save-time time {
	color: #ccc;
}