.list-group-item {
	margin: 5px 0;
}

.project-item-period,
.project-item-period time,
.project-item-period span {
	font-size: 90%;
	color: #ccc;
}

.project-item-dts {
	color: #ccc;
	font-size: 90%;
	display: inline-block;
	margin-left: .5em;
}

.project-item-err {
	color: #f00;
}

.project-item-aspects {
	margin-bottom: .3em;
}

.project-item-aspects dt,
.project-item-aspects dd,
.project-item-assignee dt,
.project-item-assignee dd
  {
	display: inline-block;
	float: none;
	padding: 0;
	font-weight: normal;
	font-size: 90%;
}

.project-item-params span {
	padding-right: 5px;
	color: #ccc;
	font-size: 90%;
}

.project-item-aspects dt,
.project-item-assignee dt {
	margin-right: 5px;
	color: #ccc;
}

.project-item-aspects dd,
.project-item-assignee dd {
	margin-right: 10px;
	word-break: break-word;
}

.project-item-assignee {
	display: inline-block;
}

.project-list-period {
	margin-bottom: 2em;
}

.project-status-placeholder {
	float: right;
}

.project-item-remove,
.project-item-archive,
.project-item-restore {
	float: right;
}

.project-item-restore {
	margin-right: 20px;
}

.project-item-rm-link,
.project-item-arch-link,
.project-item-restore-link {
	visibility: visible;
	color: #ccc;
}

.project-item-rm-link:hover,
.project-item-arch-link:hover {
	color: #f00;
	cursor: pointer;
}

.project-item-restore-link:hover {
	color: #2fd18e;
	cursor: pointer;
}