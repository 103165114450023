.loader-active {
    background: rgba(64, 64, 64, 0.5);
    width: 100%;
    min-height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
}

.loader-active.hide-backdrop {
    background: rgba(64, 64, 64, 0);
}

.loader-inactive {
	display: none;
}

.loader-body {
    padding: 20px;
    background-color: #2FD18E;
    border-radius: 5px;
    color: #fff;
    margin-top: 60px;
    text-align: center;
}

.loader-label {
    margin-left: 1rem;
    color: #fff;
}

.fa-spinner{
    color: #fff;
    font-size: 30px;
    margin-right: 10px;
}

.modal-backdrop {
	opacity: .1;
}
