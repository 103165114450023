.step {
	width: 100%;
    height: 66px;
    border: 2px dashed rgba(47, 209, 142, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
    border-radius: 5px;
    transition-duration: .3s;
    padding: 0 7px;
}

.step-active, .step:hover {
    background: #FFFFFF;
    border: none;
    box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
}

.step-num-wrap {
	width: 26px;
    height: 26px;
    border-radius: 22px;
    border: 1px solid #2FD18E;
}

.step-num {
    width: 22px;
    height: 22px;
    background: #2FD18E;
    border-radius: 22px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

.step-name {
    font-weight: 700;
    margin-left: 7px;
}