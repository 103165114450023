.inline-editor {
    display: flex;
}
.inline-editor > input {
    width: 100%;
}
.inline-editor > label {
    border-bottom: 1px dashed;
    cursor: pointer;
}
.inline-editor > button {
    border: none;
    background: none;
    color: #007bff;
    font-size: 18px;
    padding-left: 10px;
}