.okud-status {
	display: inline-block;
	margin-left: 1rem;
	font-size: 90%;
}

.okud-status span {
	margin: 0 10px;
}

.okud-status .pseudo-link {
	color: #ccc;
}
