.project-filter {
	margin-bottom: 20px;
}

.project-filter-archive {
	display: inline-block;
}

.project-filter-archive label:hover {
	cursor: pointer;
}

.react-datepicker-wrapper {
	position: relative;
	display: inline-block;
	margin-left: 2em;
}

.react-datepicker-wrapper input {
	font-size: 13px;
	border-radius: 4px;
	box-shadow: inset 0 2px 2px #e9e9e9;
	border: 1px solid #aeaeae;
	line-height: 16px;
	padding: 6px 10px 5px;
}
.react-datepicker-wrapper input:focus {
	outline: none;
    border-color: #aeaeae;
    box-shadow: inset 0 2px 2px #e9e9e9, 0 0 10px 0 rgba(73, 107, 125, 0.3);
}

.react-datepicker__close-icon::after {
	background-color: #404040;
}