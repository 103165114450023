.project-status {
	display: inline-block;
	font-size: 90%;
	text-align: right;
	margin-left:  10px;
}

.project-status span {
	margin-right: 10px;
}

.project-status .pseudo-link {
	color: #ccc;
}

.project-reg-date {
	color: #007bff;
}

.project-reg-block-inline {
	display: inline-block;
}

.project-reg-block dt {
	display: inline-block;
	font-weight: normal;
	color: #ccc;
	float: none;
	padding-right: 5px;
}

.project-reg-block dd {
	display: inline-block;
}
