.company-fund-list {
    table {
        th {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            vertical-align: top;
            &.is-kval {
                min-width: 115px;
            }
        }
        .btn-delete {
            color: #dc3545;
            cursor: pointer;
            opacity: 0;
        }
        tr {
            td em {
                font-style: normal;
            }
            &:hover {
                td em {
                    color: #0056b3;
                    cursor: pointer;
                }
                .btn-delete {
                    opacity: .75;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}