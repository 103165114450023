.table thead th, .table tbody td {
	border: 0;
}

.view-table-wrap {
	text-align: right;
}

.view-table-title {
	margin: 1rem;
	text-align: center;
}

.view-table {
	background: #FFFFFF;
	border-collapse: collapse;
    border-spacing: 0;
	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
	border-radius: 5px;
	border: 1px solid rgba(230, 242, 245, 0.75);
	margin-bottom: 26px;
	width: 100%;
}

.view-table th {
	border: none;
    padding: 4px 7px 4px 7px;
    font-size: 11px;
    line-height: 13px;
}

.view-table td {
	border: none;
    padding: 14px 10px 14px 10px;
    font-size: 14px;
    line-height: 15px;
}

.view-table th {
	font-weight: 700;
	border-right: 1px solid rgba(230, 242, 245, 0.75);
}

.view-table th div {
	font-weight: 700;
	max-height: 80px;
	overflow: hidden;
}

.view-table thead tr {
    background-color: rgba(232, 249, 243, 1);
}

.view-table thead {
	position: sticky;
	top: 0;
	z-index: 1;
}

.view-table tbody tr:nth-child(2n+2){
	background-color: rgba(250, 251, 252, 0.5);
}

.edit-cell {
	white-space: nowrap;
}

.edit-select {
	max-width: 250px;
}

.edit-cell-value {
	border: 1px solid #ccc;
	display: inline-block;
	min-width: 85px;
}

.edit-cell-value-list {
	white-space: normal !important;
}

.edit-cell-currency,
.edit-cell-decimals-wrap,
.edit-cell-decimals {
	color: #ccc;
	font-size: 90%;
	display: inline-block;
}

.edit-cell-currency {
	margin-right: 4px;
}

.edit-cell-decimals-wrap {
	margin-left: 4px;
}

.edit-cell-error {
	border-color: #f00;
}

.view-table-footer {
	text-align: left;
	margin-top: -2em;
}

.btn-add-row {
	color: #aaa;
	font-size: 80%;
	padding:  2px;
}

.btn-rm-row {
	visibility: hidden;
	cursor: pointer;
	color: #f00;
	left: -3em;
	position: relative;
}

.btn-copy-row {
	visibility: hidden;
	cursor: pointer;
	left: 0em;
	position: relative;
}

.view-table tr:hover .btn-rm-row,
.view-table tr:hover .btn-copy-row {
	visibility: visible;
}

.react-datepicker__input-container {
	position: initial;
}
