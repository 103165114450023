.report-info {
	/*padding: 31px 41px 25px;
	margin-bottom: 26px;
	background-color: #fff;
  	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
  	border-radius: 5px;*/
}

.report-info-main {
	font-size: 24px;
	font-weight: 700;
}

.report-info dl {
	width: 100%;
	background: #FAFBFC;
	border-radius: 5px;
	font-size: 14px;
	padding: 0 40px;
	margin-top: 18px;
}

.report-info dt, .report-info dd {
	font-weight: 400;
	padding: 10px 0;
	padding-right: 10px;
}

.report-info dd {
	font-weight: 700;
}
