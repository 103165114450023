.check-entry-formula-expression {
	font-family: monospace !important;
}

.check-entry-formula-variables {
	padding: 0;
}

.check-entry-formula-variables li {
	font-weight: 400;
	border-top: 1px solid rgba(125, 125, 232, 0.1);
	line-height: 21px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	padding: 5px 0px;
	font-family: monospace !important;
}

.check-entry-formula-variables li:first-child {
	border-top: 0;
}