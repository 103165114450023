.edit-fund-modal {
    .form-control.is-invalid {
        background-image: none;
    }
    .position-relative.form-group.required {
        margin-bottom: 1.6rem;
    }
    .form-check {
        margin-bottom: 1rem;
    }
}