.check-entry {
	padding: 31px 41px 25px;
	margin-bottom: 26px;
}

.check-entry-title {
	text-align: left;
	font-size: 18px;
	font-weight: 700;
	margin-right: 9px;
}

.check-entry-count {
	width: 22px;
	height: 22px;
	font-size: 11px;
	font-weight: 700;
	background: #FF3100;
	border-radius: 17px;
	color: #fff;
}

.check-entry-descr {
	font-size: 14px;
	margin: 19px 0 16px 0;
	line-height: 21px;
}

.check-entry-details {
	width: 100%;
	background: #FAFBFC;
	border-radius: 5px;
	font-size: 14px;
	padding: 0 20px;
}
