.project-select {
	margin: 1em 0;
}

.list-group-item {
	margin: 5px 0;
}

.project-item-period {
	font-size: 90%;
}

.project-item-dts {
	color: #ccc;
	font-size: 90%;
}