.check-cbr-list {
	width: 100%;
	background: #FAFBFC;
	border-radius: 5px;
	font-size: 14px;
	padding: 0;
}

.check-cbr-list li {
	font-weight: 400;
	border-top: 1px solid rgba(125, 125, 232, 0.1);
	line-height: 21px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	padding: 5px 0px;
	padding-right: 10px;
}

.check-cbr-list li:first-child {
	border-top: 0;
}