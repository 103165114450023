.project-index {
	position: fixed;
	width: 250px;
	left: 1em;
    background: #fff;
}
.project-index ul {
	padding: 5px 5px;
}
.project-index ul li {
	padding: 0 0 15px 0;
}
.project-index div {
	font-size: .95em;
	padding:  0px;
}