.check-summary {
    padding: 40px 10px;
    margin-bottom: 26px;
    text-align: center;
}

.check-summary-text {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    padding-bottom: 19px;
    margin: 0;
}

.check-summary-text p {
	margin: 0;
    font-weight: 700;
}

.context-loader {
	text-align: center;
	margin-bottom: 1em;
    padding: 5px 10px;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.context-loader .fa-spinner {
	font-size: 18px;
	color: #856404;
}