.company {
    .group {
        position: relative;
        border: 1px solid #d8d8d8;
        margin: 1.5rem 0 0;
        border-radius: 0.25rem;

        &:last-child {
            margin-bottom: 1.5rem;
        }
    }
    
    .group-header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
    
    .group-body {
        padding: 0.75rem 1.25rem;
    }
}