.file-upload-zone {
	display: inline-block;
    cursor: pointer !important;
}

.file-upload-link {
}

.file-item {
	margin-right: 1rem !important;
}

.file-item-link {
	color: #ccc !important;
}

.project-file-rm {
	color: #ff000073;
	cursor: pointer;
	margin-left: 5px;
}