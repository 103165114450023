*{
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	color: #404040;
}
body{
	background: #FAFBFC;
	color: #404040;
	margin: 0;
	/*margin-bottom: 150px;*/
}
a, a:hover{
	text-decoration: none;
}
li{
	list-style-type: none;
}
dd{
	margin: 0;
}
dl dt {
 float:left;
 width:auto;
}

dl dd {
 padding:5px 0;
}
.flex{
	justify-content: center;
    -ms-align-items: center;
    align-items: center;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
.flex_align-center{
    -ms-align-items: center;
    align-items: center;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
.navbar-component {
	background: #FFFFFF;
	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
}
.new-project, .project-list, .user{
	font-weight: 700;
	font-size: 16px;
	cursor: pointer;
	color: #404040!important;
}
.new-project, .project-list {
	color: #2FD18E!important;
	margin-right: 22px;
}
.dropdown-toggle::after{
	color: #2FD18E;
}

.project-section {
	margin-bottom: 1em;
}

.dropdown-item{
	font-weight: 700;
	font-size: 16px;
}
.dropdown-menu {
	border: none;
	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
}
.dropdown-menu:hover .dropdown-item,
.dropdown-menu:hover .fa-sign-out-alt{
	color: #2FD18E;
	-webkit-transition-duration: .3s;
	        transition-duration: .3s;
}
.navbar-expand-lg .navbar-nav .dropdown-menu{
	left: -71px;
}

.bg-light {
	background-color: #fff !important;
}

.report-all_wrap{
	padding: 40px 10px;
	margin-bottom: 26px;
}
.bg_white{
	background-color: #fff;
	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
	border-radius: 5px;
}
.step-header{
	font-size: 28px;
	font-weight: 700;
	text-align: center;
	padding: 31px 0;
	margin: 0;
}
.step-header-actions {
	display: inline-block;
    float: right;
    font-size: medium;
    font-size: initial;
}
.step-header-actions a {
	margin: 0 10px;
}
.report-all_alert{
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	font-weight: 700;
	padding-bottom: 19px;
	margin: 0;
}
.text_allert{
	color: #FF3100;
	font-weight: 700;
}
.button{
	width: 207px;
	height: 55px;
	background: #2FD18E;
	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
	border-radius: 5px;
	font-weight: 700;
	font-size: 15px;
	color: #fff;
	margin: 0 auto;
	
}
.button:hover{
	box-shadow: 0px 0px 0px rgba(187, 202, 209, 0.1);
	background: #6BDCAE;
	-webkit-transition-duration: .5s;
	        transition-duration: .5s;
}
.report_wrap{
	padding: 31px 41px 25px;
	margin-bottom: 26px;
}
.arrow .fas{
	color: #fff;
	margin: 0px 5px;
}
.arrow_green .fas{
	color: #2FD18E;
	margin: 0px 5px;
}
.arrow_out .fas{
	margin: 0px 10px;
}

.loading_wrap{
	border-radius: 5px;
	border: 2px dashed #2FD18E;
	margin: 50px 0 41px;
	cursor: pointer;
	padding: 20px 20px 56px;
}
.loading-ico .fas{
	font-size: 55px;
	color: #2FD18E;
	text-align: center;
	margin-top: 42px;
	width: 100%;
}
.loading-title, .loading-subtitle{
	text-align: center;
	font-size: 18px;
	font-weight: 400;
}
.loading-title{
	margin: 21px 0 10px;
}
.loading-subtitle{
	color: #2FD18E;
}
.table-report {
    border-collapse: collapse;
    border-spacing: 0;
    overflow-x: auto;
}
.tg-item_border{
	border-bottom: 1px solid rgba(230, 242, 245, 0.75);
}
.table-tr .table-td:last-child{
	border-right: 0px solid rgba(230, 242, 245, 0.75);
}

.nav-link {
	cursor: pointer;
}

.pseudo-link {
	border-bottom: 1px dashed;
	cursor: pointer;
}

.breadcrumps-container {
	padding-top: 8px;
	padding-bottom: 8px;
	align-items: center;
	border-bottom: 1px solid #dbdbdb;
}

.breadcrumps-container .breadcrumbs__section:not(:last-child) {
	margin-right: 1em

}

.breadcrumps-container .breadcrumbs__section:not(:last-child):after {
	font-family: "Font Awesome 5 Free";
	content: '\F30B';
	padding-left: 1em;
	display: inline-block;
	vertical-align: middle;
	font-weight: 600;
	color: #ccc;
}

.breadcrumps-container .breadcrumbs__separator {
	display: none;
}

.breadcrumps-container a {
	font-size: 12px;
	color: #777;
}

.sidenav---sidenav---_2tBP {
	background: #2FD18E !important;
}

@media (min-width: 1900px) {
	.view-container {
		margin-left: 0px !important;
	}
	.breadcrumps-container {
		margin-left: 0;
	}
}
@media (max-width: 1699px) {
	.view-container {
		margin-left: 200px !important;
	}
	.breadcrumps-container {
		margin-left: 0;
	}
}


@media (min-width: 1500px) {
	.container {
	    max-width: 1240px;
	}
	.view-table-wrap {
		margin-left: -15%;
	}
	.breadcrumps-container {
		margin-left: 15%;
	}
}
@media (max-width: 1499px) {
	.container {
	    max-width: 1240px;
	}
	.view-table-wrap {
		margin-left: 0%;
	}
	.breadcrumps-container {
		margin-left: 15%;
	}
}

@media (max-width: 1299px) {
	.container {
	    max-width: 1140px;
	}
	.view-table-wrap {
		margin-left: 0%;
	}
	.breadcrumps-container {
		margin-left: 20%;
	}
}

@media (max-width: 991px){
	.breadcrumps-container {
		margin-left: 25%;
	}
}
@media (max-width: 810px){

}

@media (max-width: 768px){
	.tab{
		margin-bottom: 8px;
	}
	.report_li{
	    padding: 10px 0;
	}
	.report_wrap {
	    padding: 31px 25px 25px;
	}
	.card-title {
	    width: 90%;
	}
	.control_step .button{
		margin-bottom: 8px;
		width: 100%;
	}
	.breadcrumps-container {
		margin-left: 30%;
	}
}

@media (max-width: 500px){
	
}

@media (max-width: 450px){
	.report_ul {
	    padding: 0 20px;
	}

}
.loader-active {
    background: rgba(64, 64, 64, 0.5);
    width: 100%;
    min-height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
}

.loader-active.hide-backdrop {
    background: rgba(64, 64, 64, 0);
}

.loader-inactive {
	display: none;
}

.loader-body {
    padding: 20px;
    background-color: #2FD18E;
    border-radius: 5px;
    color: #fff;
    margin-top: 60px;
    text-align: center;
}

.loader-label {
    margin-left: 1rem;
    color: #fff;
}

.fa-spinner{
    color: #fff;
    font-size: 30px;
    margin-right: 10px;
}

.modal-backdrop {
	opacity: .1;
}

.error-allert {
  width: 100%;
  padding: 20px 28px;
  background-color: rgba(235, 87, 87, 0.18);
  border-radius: 5px;
  margin-bottom: -31px;
}
.react-datepicker-wrapper {
	display: block;
}
.project-status {
	display: inline-block;
	font-size: 90%;
	text-align: right;
	margin-left:  10px;
}

.project-status span {
	margin-right: 10px;
}

.project-status .pseudo-link {
	color: #ccc;
}

.project-reg-date {
	color: #007bff;
}

.project-reg-block-inline {
	display: inline-block;
}

.project-reg-block dt {
	display: inline-block;
	font-weight: normal;
	color: #ccc;
	float: none;
	padding-right: 5px;
}

.project-reg-block dd {
	display: inline-block;
}

.list-group-item {
	margin: 5px 0;
}

.project-item-period,
.project-item-period time,
.project-item-period span {
	font-size: 90%;
	color: #ccc;
}

.project-item-dts {
	color: #ccc;
	font-size: 90%;
	display: inline-block;
	margin-left: .5em;
}

.project-item-err {
	color: #f00;
}

.project-item-aspects {
	margin-bottom: .3em;
}

.project-item-aspects dt,
.project-item-aspects dd,
.project-item-assignee dt,
.project-item-assignee dd
  {
	display: inline-block;
	float: none;
	padding: 0;
	font-weight: normal;
	font-size: 90%;
}

.project-item-params span {
	padding-right: 5px;
	color: #ccc;
	font-size: 90%;
}

.project-item-aspects dt,
.project-item-assignee dt {
	margin-right: 5px;
	color: #ccc;
}

.project-item-aspects dd,
.project-item-assignee dd {
	margin-right: 10px;
	word-break: break-word;
}

.project-item-assignee {
	display: inline-block;
}

.project-list-period {
	margin-bottom: 2em;
}

.project-status-placeholder {
	float: right;
}

.project-item-remove,
.project-item-archive,
.project-item-restore {
	float: right;
}

.project-item-restore {
	margin-right: 20px;
}

.project-item-rm-link,
.project-item-arch-link,
.project-item-restore-link {
	visibility: visible;
	color: #ccc;
}

.project-item-rm-link:hover,
.project-item-arch-link:hover {
	color: #f00;
	cursor: pointer;
}

.project-item-restore-link:hover {
	color: #2fd18e;
	cursor: pointer;
}
.project-filter {
	margin-bottom: 20px;
}

.project-filter-archive {
	display: inline-block;
}

.project-filter-archive label:hover {
	cursor: pointer;
}

.react-datepicker-wrapper {
	position: relative;
	display: inline-block;
	margin-left: 2em;
}

.react-datepicker-wrapper input {
	font-size: 13px;
	border-radius: 4px;
	box-shadow: inset 0 2px 2px #e9e9e9;
	border: 1px solid #aeaeae;
	line-height: 16px;
	padding: 6px 10px 5px;
}
.react-datepicker-wrapper input:focus {
	outline: none;
    border-color: #aeaeae;
    box-shadow: inset 0 2px 2px #e9e9e9, 0 0 10px 0 rgba(73, 107, 125, 0.3);
}

.react-datepicker__close-icon::after {
	background-color: #404040;
}
.project-decimals {
	width: 100px;
}

.ep-item-period {
	color: #ccc;
	font-size: 90%;
}

.period-label {
	padding-left: 0 !important;
}

.project-is-temp-comment {
	color: #ccc;
	font-size: 90%;
	padding-left: 5px;
}

.project-is-temp-label {
	margin-left: 20px;	
}

.project-select {
	margin: 1em 0;
}

.list-group-item {
	margin: 5px 0;
}

.project-item-period {
	font-size: 90%;
}

.project-item-dts {
	color: #ccc;
	font-size: 90%;
}
.drop-zone {
  border-radius: 5px;
  border: 2px dashed #2FD18E;
  margin: 50px 0 41px;
  cursor: pointer;
  padding: 20px 20px 56px;
}

.drop-zone .fas {
  font-size: 55px;
  color: #2FD18E;
  text-align: center;
  margin-top: 42px;
  width: 100%;
}

.drop-zone-title, .drop-zone-descr {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.drop-zone-title {
  color: #2FD18E;
  margin: 21px 0 10px;
}

.step {
	width: 100%;
    height: 66px;
    border: 2px dashed rgba(47, 209, 142, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
    border-radius: 5px;
    -webkit-transition-duration: .3s;
            transition-duration: .3s;
    padding: 0 7px;
}

.step-active, .step:hover {
    background: #FFFFFF;
    border: none;
    box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
}

.step-num-wrap {
	width: 26px;
    height: 26px;
    border-radius: 22px;
    border: 1px solid #2FD18E;
}

.step-num {
    width: 22px;
    height: 22px;
    background: #2FD18E;
    border-radius: 22px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

.step-name {
    font-weight: 700;
    margin-left: 7px;
}
.steps {
	margin-top: 30px;
}
.inline-editor {
    display: flex;
}
.inline-editor > input {
    width: 100%;
}
.inline-editor > label {
    border-bottom: 1px dashed;
    cursor: pointer;
}
.inline-editor > button {
    border: none;
    background: none;
    color: #007bff;
    font-size: 18px;
    padding-left: 10px;
}
.report-info {
	/*padding: 31px 41px 25px;
	margin-bottom: 26px;
	background-color: #fff;
  	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
  	border-radius: 5px;*/
}

.report-info-main {
	font-size: 24px;
	font-weight: 700;
}

.report-info dl {
	width: 100%;
	background: #FAFBFC;
	border-radius: 5px;
	font-size: 14px;
	padding: 0 40px;
	margin-top: 18px;
}

.report-info dt, .report-info dd {
	font-weight: 400;
	padding: 10px 0;
	padding-right: 10px;
}

.report-info dd {
	font-weight: 700;
}

.form-login {
    max-width: 380px;
    padding: 15px 35px 45px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.form-signup {
    max-width: 380px;
    padding: 15px 35px 45px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.auth-container {
    max-width: 380px;
    padding: 15px 35px 45px;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 50px;
}
.ro-form {
	margin: 0 40px
}

.ro-descr {
    font-size: 80%;
    color: #999;
}
.userSelect__control {
	min-width: 200px;
}

.userSelect__value-container {
	height: 35px;
}

.userSelect__control .userSelect__clear-indicator path {
	color: #ff000073;
}
.project-actions {
	margin: 1em;
}

.project-actions a {
	padding: 1rem 3rem;
}

.project-assignee {
	margin-left: 3em;
}

.file-upload-zone {
	display: inline-block;
    cursor: pointer !important;
}

.file-upload-link {
}

.file-item {
	margin-right: 1rem !important;
}

.file-item-link {
	color: #ccc !important;
}

.project-file-rm {
	color: #ff000073;
	cursor: pointer;
	margin-left: 5px;
}
.upload-errors-modal {
	min-width: 1000px
}

.upload-item-name {
    color: #ccc;
    border-bottom: 1px solid #ccc;
    margin-bottom: 3px;
    padding-bottom: 3px;
}
.okud-status {
	display: inline-block;
	margin-left: 1rem;
	font-size: 90%;
}

.okud-status span {
	margin: 0 10px;
}

.okud-status .pseudo-link {
	color: #ccc;
}

.project-okuds .nav {
	display: inline-flex;
    float: right;
}

.project-okuds .nav-item {
	margin: 0 10px;
}

.project-okuds .nav-link {
	color: #007bff !important;
}

.project-okud dl {
	margin-bottom: .3em;
}

.project-okud dt,
.project-okud dd  {
	display: inline-block;
	float: none;
	padding: 0;
	font-weight: normal;
	font-size: 90%;
}

.project-okud dt {
	margin-right: 5px;
	color: #ccc;
}

.project-okud dd {
	margin-right: 10px;
}

.project-okud-date-change,
.project-okud-add {
	color: #ccc;
}

.project-okud-date-change {
	display: none;
}

.project-okud-title {
	margin-bottom: 1em;
}

.project-okud-code {
	color: #ccc;
	font-size: 90%;
}

.project-okud-label {
	padding-left: .5em;
}

.project-okud-add {
	margin: 0 10px;
}

.project-okud-item {
	margin-bottom: 1em;
}

.project-okud-rm-link {
	visibility: visible;
	color: #ccc;
}

.project-okud-rm-link:hover {
	color: #f00;
}

/*.project-okud-actions {
	visibility: hidden;
}

.project-okud-item:hover .project-okud-actions {
	visibility: visible;
}*/
.check-entry {
	padding: 31px 41px 25px;
	margin-bottom: 26px;
}

.check-entry-title {
	text-align: left;
	font-size: 18px;
	font-weight: 700;
	margin-right: 9px;
}

.check-entry-count {
	width: 22px;
	height: 22px;
	font-size: 11px;
	font-weight: 700;
	background: #FF3100;
	border-radius: 17px;
	color: #fff;
}

.check-entry-descr {
	font-size: 14px;
	margin: 19px 0 16px 0;
	line-height: 21px;
}

.check-entry-details {
	width: 100%;
	background: #FAFBFC;
	border-radius: 5px;
	font-size: 14px;
	padding: 0 20px;
}

.check-cbr-list {
	width: 100%;
	background: #FAFBFC;
	border-radius: 5px;
	font-size: 14px;
	padding: 0;
}

.check-cbr-list li {
	font-weight: 400;
	border-top: 1px solid rgba(125, 125, 232, 0.1);
	line-height: 21px;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	padding: 5px 0px;
	padding-right: 10px;
}

.check-cbr-list li:first-child {
	border-top: 0;
}
.check-entry-formula-expression {
	font-family: monospace !important;
}

.check-entry-formula-variables {
	padding: 0;
}

.check-entry-formula-variables li {
	font-weight: 400;
	border-top: 1px solid rgba(125, 125, 232, 0.1);
	line-height: 21px;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	padding: 5px 0px;
	font-family: monospace !important;
}

.check-entry-formula-variables li:first-child {
	border-top: 0;
}
.empty-project {
  text-align: center;
  margin: 1em 0;
}
.check-summary {
    padding: 40px 10px;
    margin-bottom: 26px;
    text-align: center;
}

.check-summary-text {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    padding-bottom: 19px;
    margin: 0;
}

.check-summary-text p {
	margin: 0;
    font-weight: 700;
}

.context-loader {
	text-align: center;
	margin-bottom: 1em;
    padding: 5px 10px;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.context-loader .fa-spinner {
	font-size: 18px;
	color: #856404;
}
.table thead th, .table tbody td {
	border: 0;
}

.view-table-wrap {
	text-align: right;
}

.view-table-title {
	margin: 1rem;
	text-align: center;
}

.view-table {
	background: #FFFFFF;
	border-collapse: collapse;
    border-spacing: 0;
	box-shadow: 0px 0px 30px rgba(187, 202, 209, 0.1);
	border-radius: 5px;
	border: 1px solid rgba(230, 242, 245, 0.75);
	margin-bottom: 26px;
	width: 100%;
}

.view-table th {
	border: none;
    padding: 4px 7px 4px 7px;
    font-size: 11px;
    line-height: 13px;
}

.view-table td {
	border: none;
    padding: 14px 10px 14px 10px;
    font-size: 14px;
    line-height: 15px;
}

.view-table th {
	font-weight: 700;
	border-right: 1px solid rgba(230, 242, 245, 0.75);
}

.view-table th div {
	font-weight: 700;
	max-height: 80px;
	overflow: hidden;
}

.view-table thead tr {
    background-color: rgba(232, 249, 243, 1);
}

.view-table thead {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1;
}

.view-table tbody tr:nth-child(2n+2){
	background-color: rgba(250, 251, 252, 0.5);
}

.edit-cell {
	white-space: nowrap;
}

.edit-select {
	max-width: 250px;
}

.edit-cell-value {
	border: 1px solid #ccc;
	display: inline-block;
	min-width: 85px;
}

.edit-cell-value-list {
	white-space: normal !important;
}

.edit-cell-currency,
.edit-cell-decimals-wrap,
.edit-cell-decimals {
	color: #ccc;
	font-size: 90%;
	display: inline-block;
}

.edit-cell-currency {
	margin-right: 4px;
}

.edit-cell-decimals-wrap {
	margin-left: 4px;
}

.edit-cell-error {
	border-color: #f00;
}

.view-table-footer {
	text-align: left;
	margin-top: -2em;
}

.btn-add-row {
	color: #aaa;
	font-size: 80%;
	padding:  2px;
}

.btn-rm-row {
	visibility: hidden;
	cursor: pointer;
	color: #f00;
	left: -3em;
	position: relative;
}

.btn-copy-row {
	visibility: hidden;
	cursor: pointer;
	left: 0em;
	position: relative;
}

.view-table tr:hover .btn-rm-row,
.view-table tr:hover .btn-copy-row {
	visibility: visible;
}

.react-datepicker__input-container {
	position: static;
	position: initial;
}

.editor-panel .alert {
	padding: .05rem 1.25rem;
	font-size: 90%;
}

.editor-panel .btn-link {
	font-size: 90%;
}

.btn-draft-remove,
.btn-draft-exit {
	float: right;
    color: #F00;
}

.btn-draft-exit {
	color: #333;
}

.draft-save-time {
	float: right;
	color: #ccc;
}

.draft-save-time time {
	color: #ccc;
}
.project-index {
	position: fixed;
	width: 250px;
	left: 1em;
    background: #fff;
}
.project-index ul {
	padding: 5px 5px;
}
.project-index ul li {
	padding: 0 0 15px 0;
}
.project-index div {
	font-size: .95em;
	padding:  0px;
}
.view-container {
	margin: 0 20px;
	margin-left: 200px;
}

.tables-container {
	background: #fafbfc;
}

.tables-container .react-datepicker-popper {
	z-index: 2;
}

.last-save-time {
	position: fixed;
	top: 170px;
	width: 200px;
	left: 1em;
	height: 50px;
	padding: 0 10px;
	color: #ccc;
	font-size: 0.8em;
/*    background-color: #fff3cd;
    border-color: #ffeeba;*/
}

.last-save-time div,
.last-save-time time,
.last-save-time .saving-spinner {
	color: #ccc;
}

.saving-spinner {
	font-size: .875em;
}

.project-comment {
	position: fixed;
	top: 69px;
	width: 200px;
	left: 1em;
	padding: 0 10px;
	color: #ccc;
	font-size: 0.73em;
	height: 100px;
    overflow: hidden;
    background-color: #fafbfc;
}

.project-comment span {
	display: block;
    font-size: 0.95em;
    font-weight: 600;
}
.check-summary {
    padding: 40px 10px;
    margin-bottom: 26px;
}

.check-summary-text {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    padding-bottom: 19px;
    margin: 0;
}

.check-summary-text p {
	margin: 0;
    font-weight: 700;
}

.context-loader {
	text-align: center;
	margin-bottom: 1em;
    padding: 5px 10px;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.context-loader .fa-spinner {
	font-size: 18px;
	color: #856404;
}
.company .group {
  position: relative;
  border: 1px solid #d8d8d8;
  margin: 1.5rem 0 0;
  border-radius: 0.25rem; }
  .company .group:last-child {
    margin-bottom: 1.5rem; }

.company .group-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.company .group-body {
  padding: 0.75rem 1.25rem; }

.company-info .hidden {
  display: none; }

.company-user-list .badge-curr-user {
  margin-left: 5px; }

.company-fund-list table th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  vertical-align: top; }
  .company-fund-list table th.is-kval {
    min-width: 115px; }

.company-fund-list table .btn-delete {
  color: #dc3545;
  cursor: pointer;
  opacity: 0; }

.company-fund-list table tr td em {
  font-style: normal; }

.company-fund-list table tr:hover td em {
  color: #0056b3;
  cursor: pointer; }

.company-fund-list table tr:hover .btn-delete {
  opacity: .75; }
  .company-fund-list table tr:hover .btn-delete:hover {
    opacity: 1; }

.edit-fund-modal .form-control.is-invalid {
  background-image: none; }

.edit-fund-modal .position-relative.form-group.required {
  margin-bottom: 1.6rem; }

.edit-fund-modal .form-check {
  margin-bottom: 1rem; }

