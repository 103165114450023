.drop-zone {
  border-radius: 5px;
  border: 2px dashed #2FD18E;
  margin: 50px 0 41px;
  cursor: pointer;
  padding: 20px 20px 56px;
}

.drop-zone .fas {
  font-size: 55px;
  color: #2FD18E;
  text-align: center;
  margin-top: 42px;
  width: 100%;
}

.drop-zone-title, .drop-zone-descr {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.drop-zone-title {
  color: #2FD18E;
  margin: 21px 0 10px;
}
